import React from 'react';
import passport from '../../images/passport.png';
import Icon_phan_mem from '../../images/Icon_phan_mem.png';

export default function MobileApp() {
  return (
    <div className="w-[380px] lg:w-[1000px]  lg:h-[250px]  rounded-[20px] shadow-md bg-[#FFFFFF] my-[15px] mx-[20px] lg:mx-auto">
      <div className="lg:flex flex-row-reverse">
        <div className="lg:w-2/3 h-full ">
          <div className="flex">
            <img className="mt-[35px] lg:mt-[20px] mr-[20px] ml-[30px] w-[50px] h-[50px]" src={passport} />
            <h2 className="font-bold text-2xl mt-[25px] text-[#2EBBEC]">Build websites, mobile applications</h2>
          </div>
          <p className="mt-[35px] ml-[30px] mr-[30px] mb-[20px] text-[#3F4650]">
            We provide website design and construction services according to customer needs. With the latest technologies such as React, NodeJS, Ant Design, MongoDB, we
            ensure to bring customers the best experience.
          </p>
        </div>
        <div className="">
          <img className="h-[250px] w-[380px] " src={Icon_phan_mem} />
        </div>
      </div>
    </div>
  );
}
