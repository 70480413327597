import React from 'react';
import node from '../../images/node.png';
import layout from '../../images/layout.png';

function Node() {
  return (
    <div className="w-[380px] lg:w-[1000px]  lg:h-[250px]  rounded-[20px] shadow-md bg-[#FFFFFF] my-[15px] mx-[20px] lg:mx-auto">
      <div className="lg:flex flex-row-reverse">
        <div className="lg:w-2/3 h-full ">
          <div className="flex">
            <img className="mt-[35px] lg:mt-[20px] mr-[20px] ml-[30px] w-[50px] h-[50px]" src={layout} />
            <h2 className="font-bold text-2xl my-auto text-[#2EBBEC] mt-[40px] lg:mt-[25px]">Consulting software solutions</h2>
          </div>
          <p className="mt-[35px] ml-[30px] mr-[30px] mb-[35px] text-[#3F4650]">
            With experts with extensive experience spanning many fields such as finance, banking, payment, and sales, we help customers convert traditional business
            systems to modern, secure software systems secure and scalable.
          </p>
        </div>
        <div className="">
          <img className="h-[250px] w-[380px] " src={node} />
        </div>
      </div>
    </div>
  );
}

export default Node;
