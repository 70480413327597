import React from 'react';
import imgstar from '../../images/imgstar.png';
import box from '../../images/box.png';

export default function Transaction() {
  return (
    <div className="w-[380px] lg:w-[1000px]  lg:h-[250px]  rounded-[20px] shadow-md bg-[#FFFFFF] my-[15px] mx-[20px] lg:mx-auto">
      <div className="lg:flex flex-row-reverse">
        <div className="lg:w-2/3 h-full ">
          <div className="flex">
            <img className="mt-[35px] lg:mt-[20px] mr-[20px] ml-[30px] w-[50px] h-[50px]" src={box} />
            <h2 className="font-bold text-2xl mt-[25px] text-[#2EBBEC]">Payment intermediary service</h2>
          </div>
          <p className="mt-[35px] ml-[30px] mr-[30px] mb-[20px] text-[#3F4650]">
            We provide intermediary payment services for items such as phone cards, game cards, data packages, electricity and water payments... Integrated into internet
            banking and mobile banking applications of banks.
          </p>
          <p className="mt-[0px] ml-[30px] mr-[30px] mb-[35px] text-[#3F4650]"></p>
        </div>
        <div className="">
          <img className="h-[250px] w-[380px] " src={imgstar} />
        </div>
      </div>
    </div>
  );
}
