import Banner from 'components/banner';
import Layout from 'components/layout';
import SEO from 'components/seo';
import Design from 'components/Solutions/Design';
import PaymentPortal from 'components/Solutions/Design';
import MobileApp from 'components/Solutions/MobileApp';
import Network from 'components/Solutions/Network';
import Node from 'components/Solutions/Node';
import Technology from 'components/Solutions/Technology';
import Transaction from 'components/Solutions/transaction';
import Website from 'components/Solutions/Website';
import React from 'react';

function service() {
  return (
    <Layout>
      <SEO title="Service" />
      <Banner
        height={'400px'}
        title={'Solutions & Services'}
        content={'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour'}
      />
      <div className="container flex flex-wrap  justify-center max-w-[1200px] my-[65px] mx-auto">
        <Transaction />
        <Network />
        <Node />
        <Website />
        <MobileApp />
      </div>
    </Layout>
  );
}

export default service;
