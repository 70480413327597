import React from 'react';
import star from '../../images/star.png';
import Networkimg from '../../images/Network.png';

export default function Network() {
  return (
    <div className="w-[380px] lg:w-[1000px]  lg:h-[250px]  rounded-[20px] shadow-md bg-[#FFFFFF] my-[15px] mx-[20px] lg:mx-auto ">
      <div className="lg:flex ">
        <div className="lg:w-2/3 h-full ">
          <div className="flex">
            <img className="mt-[35px] lg:mt-[20px] mr-[20px] ml-[30px] w-[50px] h-[50px]" src={star} />
            <h2 className="font-bold text-2xl mt-[25px] text-[#2EBBEC]">Financial trading platform</h2>
          </div>
          <p className="mt-[35px] ml-[30px] mr-[30px] mb-[35px] text-[#3F4650]">
            We provide a suite of commodity derivatives trading and management software for individuals and organizations in Vietnam. We also provide personal loan
            applications combined with wallets and loan service providers.
          </p>
        </div>
        <div className="">
          <img className="h-[250px] w-[380px] " src={Networkimg} />
        </div>
      </div>
    </div>
  );
}
